// import { createRouter, createWebHistory } from 'vue-router'
// // import Signin from './components/pages/Sample1.vue'
// import Signin from './components/SignIn.vue'
// import Signup from './components/SignUp.vue'

import { createRouter, createWebHistory } from 'vue-router'
import Home from './components/HelloApp.vue'
import Signup from './components/SignUp.vue'
import Login from './components/SignIn.vue'
import UserProfile from './components/UserProfile.vue'
import MyProfile from './components/MyProfile.vue'
import ScanQRCode from './components/ScanQRCode.vue'
import UpdateQRCode from './components/UpdateQRCode.vue'
import MyChat from './components/MyChat.vue'
import ProfileKuwa from './components/ProfileKuwa.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: UserProfile
  },
  {
    path: '/MyProfile',
    name: 'MyProfile',
    component: MyProfile
  },
  {
    path: '/ScanQRCode',
    name: 'ScanQRCode',
    component: ScanQRCode
  },
  {
    path: '/UpdateQRCode',
    name: 'UpdateQRCode',
    component: UpdateQRCode
  },
  {
    path: '/MyChat',
    name: 'MyChat',
    component: MyChat
  },
  {
    path: '/ProfileKuwa',
    name: 'ProfileKuwa',
    component: ProfileKuwa
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
