<template>
    <div class="container">
      <div class="profile-pictures">
        <img class="profile-picture" src="../assets/page-img/profile-sample.png" alt="Profile Picture" />
        <img class="profile-picture" src="../assets/page-img/profile-sample.png" alt="Profile Picture" />
        <img class="profile-picture" src="../assets/page-img/profile-sample.png" alt="Profile Picture" />
      </div>
      <h1 class="name">ひより <span class="age">22</span></h1>
      <div class="alphabet">あ か さ た な は ま や ら わ ＊</div>
      <div class="input-container">
        <img src="../assets/page-img/friend.png" class="icon" alt="User Icon" />
        <input type="text" v-model="uuid" placeholder="uuid" />
      </div>
      <button class="add-button">追加</button>
      <div class="back-to-login" @click="goBack">ログイン画面へ</div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        uuid: ''
      };
    },
    methods: {
      goBack() {
        this.$router.push({ name: 'Login' });
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: #ffffff;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .profile-pictures {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .profile-picture {
    width: 100px;
    height: 150px;
    margin: 0 10px;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .name {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .age {
    font-size: 18px;
  }
  
  .alphabet {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 18px;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
    border: 1px solid #000;
    border-radius: 20px;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  input {
    flex: 1;
    border: none;
    outline: none;
  }
  
  .add-button {
    width: 100%;
    max-width: 400px;
    height: 50px;
    background-color: #ff6666;
    color: white;
    border: none;
    border-radius: 25px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .back-to-login {
    margin-top: 30px;
    font-size: 18px;
    color: #666;
    cursor: pointer;
  }
  </style>
  