import { createApp } from 'vue';
import App from './App.vue';
import router from './router'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

// Firebase設定オブジェクトを設定
const firebaseConfig = {
    apiKey: "AIzaSyDrh0NYebI6bKrm9Wvu4GaAIml6InrP0Is",
    authDomain: "tokyo-94a63.firebaseapp.com",
    projectId: "tokyo-94a63",
    storageBucket: "tokyo-94a63.appspot.com",
    messagingSenderId: "448869324815",
    appId: "1:448869324815:web:47cd1169bfb2a037c86b7a"
};

// Firebaseを初期化
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

// デバッグ用にdbをウィンドウオブジェクトに割り当て
window.db = db;

// Vueアプリケーションを作成し、マウント
const app = createApp(App)
app.use(router)
app.mount('#app')

firebase.auth().onAuthStateChanged(user => {
    if (user) {
      console.log('User is logged in');
    } else {
      console.log('User is logged out');
    }
  });
