<template>
    <div class="container">
        <img class="logo" src="../assets/page-img/illust.png" alt="HackTail Logo" />
        <button class="create-button" @click="goToSignup">新規作成</button>
        <button class="login-button" @click="goToLogin">ログイン</button>
    </div>
</template>
  
<script>
  export default {
    methods: {
      goToSignup() {
        this.$router.push({ name: 'Signup' });
      },
      goToLogin() {
        this.$router.push({ name: 'Login' });
      }
    }
  };
</script>
  
<style scoped>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: #ffffff;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .logo {
    width: 150px;
    height: 150px;
    margin-bottom: 50px;
  }
  
  .create-button {
    width: 100%;
    max-width: 400px;
    height: 50px;
    background-color: #ff6666;
    color: white;
    border: none;
    border-radius: 25px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .login-button {
    width: 100%;
    max-width: 400px;
    height: 50px;
    background-color: #66b2ff;
    color: white;
    border: none;
    border-radius: 25px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }
</style>
  