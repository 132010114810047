<template>
  <div class="container">
    <div class="qr" v-if="qrCodeUrl">
      <img :src="qrCodeUrl" alt="QR Code">
    </div>   

    <button class="load-button" @click="startUpdatingQR">QRコードの更新を開始</button>
    <div class="back-to-profile-edit" @click="goToProfileKuwa">プロフィールページ</div>

    <div class="circle-buttons">
      <button class="circle-button" @click="goToMyChat"><img src="../assets/page-img/chat_add_on.png" alt="チャットを表示"></button>
      <button class="circle-button" @click="goToScanQRCode"><img src="../assets/page-img/add_a_photo.png" alt="QRコードスキャン"></button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import QRCode from 'qrcode';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

export default {
  methods: {
    async goToScanQRCode(){
      this.$router.push('/ScanQRCode');
    },
    async goToProfileKuwa(){
      this.$router.push('/Profilekuwa');
    },
    async goToMyChat(){
      this.$router.push('/MyChat');
    },

  },
  setup() {
    const qrCodeUrl = ref(null);
    const intervalId = ref(null);
    const userId = ref(null);

    const startUpdatingQR = () => {
      if (!userId.value) {
        alert("ユーザーがログインしていません");
        return;
      }

      if (intervalId.value) {
        clearInterval(intervalId.value);
      }

      // 初回実行
      updateQRCode();

      // その後は指定された間隔で更新
      intervalId.value = setInterval(updateQRCode, 10000); // 10秒ごとに更新
    };

    const shiftString = (str, shift) => {
      return str.split('').map(char => {
        const charCode = char.charCodeAt(0);
        return String.fromCharCode(charCode + shift);
      }).join('');
    };

    const updateQRCode = async () => {
      const qrToken = Math.random().toString(36).substring(2, 15);
      const timestamp = Date.now();
      const qrContent = `${userId.value}:${qrToken}:${timestamp}`;
      console.log(`Generated QR Content before shifting: ${qrContent}`); // デバッグ用ログ

      const shiftedQRContent = shiftString(qrContent, 3);
      console.log(`Shifted QR Content: ${shiftedQRContent}`); // デバッグ用ログ

      try {
        const userDoc = firebase.firestore().collection('users').doc(userId.value);
        await userDoc.update({ currentQR: shiftedQRContent });
        qrCodeUrl.value = await QRCode.toDataURL(shiftedQRContent, { width: 200, height: 200 }); // サイズ指定
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    };

    onMounted(() => {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          userId.value = user.uid;
        } else {
          userId.value = null;
        }
      });
    });

    onBeforeUnmount(() => {
      if (intervalId.value) {
        clearInterval(intervalId.value);
      }
    });

    return {
      qrCodeUrl,
      startUpdatingQR
    };
  }
};
</script>


<style scoped>
.container {
  width: 300px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.qr {
  width: 200px; /* Adjust the width as needed */
  height: 200px; /* Adjust the height as needed */
}

p {
  margin: 5px 0;
  font-size: 0.9rem;
}

.load-button {
  width: 100%;
  max-width: 300px;
  height: 50px;
  background-color: #ff6666;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 0;
  box-sizing: border-box;

  /* ボトムに固定するための追加スタイル */
  position: fixed;
  bottom: 110px; /* ボトムからの距離を調整 */
  left: 50%;
  transform: translateX(-50%); /* 中央に配置 */
  z-index: 1000; /* 他の要素の上に表示されるように調整 */
}

.circle-buttons {
  display: flex;
  justify-content: center;
  gap: 20px; /* ボタン間のスペース */
  position: fixed;
  bottom: 50px; /* 画面の底からの距離 */
  right: -4%;
  transform: translateX(-50%);
  z-index: 1000;
}

.circle-button {
  width: 50px;
  height: 50px;
  background-color: #ff6666;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-button img {
  width: 70%;
  height: 70%;
}

.back-to-profile-edit {
  width: 100%;
  height: 50px;
  margin: 10px 0;

  font-weight: bold;
  max-width: 300px;

  margin-top: 30px;
  font-size: 18px;
  color: #666;
  cursor: pointer;

  /* ボトムに固定するための追加スタイル */
  position: fixed;
  bottom: 30px; /* ボトムからの距離を調整 */
  left: 50%;
  transform: translateX(-50%); /* 中央に配置 */
  z-index: 1000; /* 他の要素の上に表示されるように調整 */
}

@media (max-width: 768px) {

}
</style>
