<template>
  <div class="container">         
    <div v-for="(fileContent, index) in fileContents" :key="index" class="user-card">
      <h3 @click="selectAcquaintance(fileContent.name)">{{ fileContent.name }}</h3>
      <img :src="parsedContents[index]?.imageBase64 || defaultImage" class="profile-picture" alt="Profile Picture" />
      <div class="user-info">
        <h3>{{ parsedContents[index]?.name }}</h3>
        <p>年齢: {{ parsedContents[index]?.age }}</p>
        <p>{{ parsedContents[index]?.statusComment }}</p>
      </div>
    </div>


    <!-- 隠しinput要素 -->
    <input type="file" id="directoryInput" webkitdirectory @change="handleFiles" style="display: none;" />

    <!-- カスタムボタン -->
    <button class="load-button" @click="triggerFileInput">プロフィールズ読込</button>
    <div class="back-to-profile-edit" @click="goToMyProfile">プロフィール修正</div>

    <div class="circle-buttons">
      <button class="circle-button" @click="goToUpdateQRCode"><img src="../assets/page-img/qr_code.png" alt="QRコード表示"></button>
      <button class="circle-button" @click="goToScanQRCode"><img src="../assets/page-img/add_a_photo.png" alt="QRコードスキャン"></button>
    </div>
  </div>


  <MyChat v-if="selectedAcquaintance" :selectedAcquaintance="selectedAcquaintance" />
</template>




<script>
  import { ref, onMounted } from 'vue';
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/firestore';
  import 'firebase/compat/auth';
  import MyChat from './MyChat.vue';
  
  export default {
  data() {
    return {
        imageSrc: null, // 画像データを表示するための変数
        imageBase64: null, // Base64形式の画像データを格納する変数
        name: '',
        gender: '',
        age: null,
        statusComment: '',
        fileContents: [],

        defaultImage: require('../assets/page-img/no-img.png') // デフォルトのプロフィール画像
    };
  },
  computed: {
    parsedContents() {
        return this.fileContents.map(file => {
          try {
            return JSON.parse(file.content);
          } catch (e) {
            console.error('Failed to parse JSON', e);
            return {};
          }
        });
      }
  },
    components: {
      MyChat
    },
    setup() {
      const profile = ref({
        name: '',
        age: null,
        gender: 'male',
        bio: ''
      });
      const myProfile = ref(null);
      const acquaintances = ref([]);
      const selectedAcquaintance = ref(null);
      const user = ref(null);
      const profileId = ref(null);
      const newAcquaintanceUid = ref('');
  
      const submitProfile = async () => {
        if (!user.value) {
          console.error('User not logged in');
          return;
        }
        const userEmail = user.value.email;
  
        if (profileId.value) {
          // プロフィールが存在する場合は上書きする
          await firebase.firestore().collection('users').doc(user.value.uid).update({
            name: profile.value.name,
            age: profile.value.age,
            gender: profile.value.gender,
            bio: profile.value.bio,
            email: userEmail,
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
          });
        } else {
          // 新しいプロフィールを作成する
          await firebase.firestore().collection('users').doc(user.value.uid).set({
            name: profile.value.name,
            age: profile.value.age,
            gender: profile.value.gender,
            bio: profile.value.bio,
            email: userEmail,
            acquaintances: [],
            exchangedUids: [],
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
          });
          profileId.value = user.value.uid;
        }
        profile.value = { name: '', age: null, gender: 'male', bio: '' }; // フォームをリセット
        fetchMyProfile(); // プロフィールを更新
      };
  
      const fetchMyProfile = async () => {
        if (!user.value) {
          console.error('User not logged in');
          return;
        }
        const doc = await firebase.firestore().collection('users').doc(user.value.uid).get();
        if (doc.exists) {
          myProfile.value = doc.data();
          profileId.value = doc.id;
          fetchAcquaintances(doc.data().acquaintances);
        }
      };
  
      const fetchAcquaintances = async (acquaintanceUids) => {
        const promises = acquaintanceUids.map(uid => firebase.firestore().collection('users').doc(uid).get());
        const results = await Promise.all(promises);
        acquaintances.value = results.map(doc => ({ uid: doc.id, ...doc.data() }));
      };
  
      const addAcquaintance = async () => {
        if (!user.value || !newAcquaintanceUid.value) {
          console.error('User not logged in or acquaintance UID not provided');
          return;
        }
        const userUid = user.value.uid;
  
        // 自分の知り合いリストに追加
        await firebase.firestore().collection('users').doc(userUid).update({
          acquaintances: firebase.firestore.FieldValue.arrayUnion(newAcquaintanceUid.value)
        });
  
        // 知り合いリストを更新
        fetchMyProfile();
        newAcquaintanceUid.value = ''; // フォームをリセット
      };
  
      const selectAcquaintance = (acquaintance) => {
        selectedAcquaintance.value = acquaintance.replace('.txt', '');
      };
  
      onMounted(() => {
        firebase.auth().onAuthStateChanged((currentUser) => {
          user.value = currentUser;
          if (user.value) {
            fetchMyProfile();
          }
        });
      });
  
      return {
        profile,
        myProfile,
        acquaintances,
        selectedAcquaintance,
        newAcquaintanceUid,
        submitProfile,
        addAcquaintance,
        selectAcquaintance,
        user
      };
    },
    methods:{
      triggerFileInput() {
      document.getElementById('directoryInput').click();
    },
      async goToMyProfile(){
        this.$router.push('/MyProfile');
      },
      async goToScanQRCode(){
        this.$router.push('/ScanQRCode');
      },
      async goToUpdateQRCode(){
        this.$router.push('/UpdateQRCode');
      },
      convertToJson() {
        const user = firebase.auth().currentUser;
  
        const userInfo = {
          name: this.name,
          gender: this.gender,
          age: this.age,
          statusComment: this.statusComment,
          imageBase64: this.imageBase64,
          uid : user.uid
        };
        return JSON.stringify(userInfo);
      },
      handleFiles(event) {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const reader = new FileReader();
          reader.onload = (e) => {
            this.fileContents.push({
              name: file.name,
              content: e.target.result
            });
          };
          reader.readAsText(file); // テキストファイルとして読み込む
        }
      },
  
  
      readImage(event) {
        const file = event.target.files[0];
        if (!file) {
          console.log('No file selected');
          return;
        }
  
        const reader = new FileReader();
  
        reader.onload = (e) => {
          this.imageSrc = e.target.result; // 画像データを表示用に変数に格納
          this.imageBase64 = e.target.result; // Base64形式の画像データを変数に格納
  
          console.log('Base64 Encoded Image:', this.imageBase64);
          console.log(typeof this.imageBase64);
  
  
          this.inputMessage = this.imageBase64;
  
        };
  
        reader.onerror = (e) => {
          console.error('Error reading file:', e.target.error);
        };
  
        reader.readAsDataURL(file); 
      },
  
      SaveProfile(){
        let uid = firebase.auth().currentUser.uid;      
  
        this.saveTextToLocal(this.convertToJson(), uid + '.txt');
      },
  
  
      saveTextToLocal(text, filename) {
        const blob = new Blob([text], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download.txt';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      },
    }
  };
  </script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

h1 {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 20px;
}

input[type="file"] {
  margin-bottom: 20px;
}

.user-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 600px;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.profile-picture {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 10px;
}

.user-info {
  flex: 1;
}

h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

p {
  margin: 5px 0;
  font-size: 0.9rem;
}

.load-button {
  width: 100%;
  max-width: 300px;
  height: 50px;
  background-color: #ff6666;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 0;
  box-sizing: border-box;

  /* ボトムに固定するための追加スタイル */
  position: fixed;
  bottom: 110px; /* ボトムからの距離を調整 */
  left: 50%;
  transform: translateX(-50%); /* 中央に配置 */
  z-index: 1000; /* 他の要素の上に表示されるように調整 */
}

.circle-buttons {
  display: flex;
  justify-content: center;
  gap: 20px; /* ボタン間のスペース */
  position: fixed;
  bottom: 50px; /* 画面の底からの距離 */
  right: -10px;
  transform: translateX(-50%);
  z-index: 1000;
}

.circle-button {
  width: 50px;
  height: 50px;
  background-color: #ff6666;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-button img {
  width: 70%;
  height: 70%;
}

.back-to-profile-edit {
  width: 100%;
  height: 50px;
  margin: 10px 0;

  font-weight: bold;
  max-width: 300px;

  margin-top: 30px;
  font-size: 18px;
  color: #666;
  cursor: pointer;

  /* ボトムに固定するための追加スタイル */
  position: fixed;
  bottom: 30px; /* ボトムからの距離を調整 */
  left: 50%;
  transform: translateX(-50%); /* 中央に配置 */
  z-index: 1000; /* 他の要素の上に表示されるように調整 */
}

@media (max-width: 768px) {
  .user-card {
    flex-direction: row;
    align-items: flex-start;
  }

  .profile-picture {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  .user-info {
    width: 100%;
  }

  h3 {
    font-size: 1.2rem;
  }

  p {
    font-size: 1rem;
  }

}
</style>

<!-- src/components/ProfileRegistration.vue -->
<!-- <template>
    <div class="profile-form">
  
  
      </div>
  
      <div>
        <h1>ユーザー一覧のフォルダーをアップロードしてね！</h1>
        <input type="file" id="directoryInput" webkitdirectory @change="handleFiles" />
  
        <div v-for="(fileContent, index) in fileContents" :key="index">
          <h3 @click="selectAcquaintance(fileContent.name)">{{ fileContent.name }}</h3>
          <div v-if="parsedContents[index]">
            <div v-for="(value, key) in parsedContents[index]" :key="key">
              <strong>{{ key }}:</strong>
              <span v-if="key !== 'imageBase64'">{{ value }}</span>
              <img v-else :src="value" alt="Image" />
            </div>
          </div>
        </div>
  
        <MyChat v-if="selectedAcquaintance" :selectedAcquaintance="selectedAcquaintance" />
  
      </div>



  
      <button @click="goToScanQRCode">スキャンのページに移動</button>
      <button @click="goToUpdateQRCode">QRコード更新ページに移動</button>
      <button @click="goToMyProfile">プロフィール登録ページに移動</button>
    
  
  
  
  
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/firestore';
  import 'firebase/compat/auth';
  import MyChat from './MyChat.vue';
  
  export default {
  data() {
    return {
        imageSrc: null, // 画像データを表示するための変数
        imageBase64: null, // Base64形式の画像データを格納する変数
        name: '',
        gender: '',
        age: null,
        statusComment: '',
        fileContents: []
    };
  },
  computed: {
    parsedContents() {
        return this.fileContents.map(file => {
          try {
            return JSON.parse(file.content);
          } catch (e) {
            console.error('Failed to parse JSON', e);
            return {};
          }
        });
      }
  },
    components: {
      MyChat
    },
    setup() {
      const profile = ref({
        name: '',
        age: null,
        gender: 'male',
        bio: ''
      });
      const myProfile = ref(null);
      const acquaintances = ref([]);
      const selectedAcquaintance = ref(null);
      const user = ref(null);
      const profileId = ref(null);
      const newAcquaintanceUid = ref('');
  
      const submitProfile = async () => {
        if (!user.value) {
          console.error('User not logged in');
          return;
        }
        const userEmail = user.value.email;
  
        if (profileId.value) {
          // プロフィールが存在する場合は上書きする
          await firebase.firestore().collection('users').doc(user.value.uid).update({
            name: profile.value.name,
            age: profile.value.age,
            gender: profile.value.gender,
            bio: profile.value.bio,
            email: userEmail,
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
          });
        } else {
          // 新しいプロフィールを作成する
          await firebase.firestore().collection('users').doc(user.value.uid).set({
            name: profile.value.name,
            age: profile.value.age,
            gender: profile.value.gender,
            bio: profile.value.bio,
            email: userEmail,
            acquaintances: [],
            exchangedUids: [],
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
          });
          profileId.value = user.value.uid;
        }
        profile.value = { name: '', age: null, gender: 'male', bio: '' }; // フォームをリセット
        fetchMyProfile(); // プロフィールを更新
      };
  
      const fetchMyProfile = async () => {
        if (!user.value) {
          console.error('User not logged in');
          return;
        }
        const doc = await firebase.firestore().collection('users').doc(user.value.uid).get();
        if (doc.exists) {
          myProfile.value = doc.data();
          profileId.value = doc.id;
          fetchAcquaintances(doc.data().acquaintances);
        }
      };
  
      const fetchAcquaintances = async (acquaintanceUids) => {
        const promises = acquaintanceUids.map(uid => firebase.firestore().collection('users').doc(uid).get());
        const results = await Promise.all(promises);
        acquaintances.value = results.map(doc => ({ uid: doc.id, ...doc.data() }));
      };
  
      const addAcquaintance = async () => {
        if (!user.value || !newAcquaintanceUid.value) {
          console.error('User not logged in or acquaintance UID not provided');
          return;
        }
        const userUid = user.value.uid;
  
        // 自分の知り合いリストに追加
        await firebase.firestore().collection('users').doc(userUid).update({
          acquaintances: firebase.firestore.FieldValue.arrayUnion(newAcquaintanceUid.value)
        });
  
        // 知り合いリストを更新
        fetchMyProfile();
        newAcquaintanceUid.value = ''; // フォームをリセット
      };
  
      const selectAcquaintance = (acquaintance) => {
        selectedAcquaintance.value = acquaintance.replace('.txt', '');
      };
  
      onMounted(() => {
        firebase.auth().onAuthStateChanged((currentUser) => {
          user.value = currentUser;
          if (user.value) {
            fetchMyProfile();
          }
        });
      });
  
      return {
        profile,
        myProfile,
        acquaintances,
        selectedAcquaintance,
        newAcquaintanceUid,
        submitProfile,
        addAcquaintance,
        selectAcquaintance,
        user
      };
    },
    methods:{
      async goToMyProfile(){
        this.$router.push('/MyProfile');
      },
      async goToScanQRCode(){
        this.$router.push('/ScanQRCode');
      },
      async goToUpdateQRCode(){
        this.$router.push('/UpdateQRCode');
      },
      convertToJson() {
        const user = firebase.auth().currentUser;
  
        const userInfo = {
          name: this.name,
          gender: this.gender,
          age: this.age,
          statusComment: this.statusComment,
          imageBase64: this.imageBase64,
          uid : user.uid
        };
        return JSON.stringify(userInfo);
      },
      handleFiles(event) {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const reader = new FileReader();
          reader.onload = (e) => {
            this.fileContents.push({
              name: file.name,
              content: e.target.result
            });
          };
          reader.readAsText(file); // テキストファイルとして読み込む
        }
      },
  
  
      readImage(event) {
        const file = event.target.files[0];
        if (!file) {
          console.log('No file selected');
          return;
        }
  
        const reader = new FileReader();
  
        reader.onload = (e) => {
          this.imageSrc = e.target.result; // 画像データを表示用に変数に格納
          this.imageBase64 = e.target.result; // Base64形式の画像データを変数に格納
  
          console.log('Base64 Encoded Image:', this.imageBase64);
          console.log(typeof this.imageBase64);
  
  
          this.inputMessage = this.imageBase64;
  
        };
  
        reader.onerror = (e) => {
          console.error('Error reading file:', e.target.error);
        };
  
        reader.readAsDataURL(file); 
      },
  
      SaveProfile(){
        let uid = firebase.auth().currentUser.uid;      
  
        this.saveTextToLocal(this.convertToJson(), uid + '.txt');
      },
  
  
      saveTextToLocal(text, filename) {
        const blob = new Blob([text], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download.txt';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      },
    }
  };
  </script>
  
  <style scoped>
  .profile-form {
    max-width: 600px;
    margin: auto;
    padding: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-group {
    margin-bottom: 1em;
  }
  
  label {
    display: block;
    margin-bottom: 0.5em;
  }
  
  input, textarea, select {
    width: 100%;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 0.7em 1.5em;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    padding: 0.5em;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
  }
  
  li:hover {
    background-color: #f0f0f0;
  }
  </style> -->