<template>
  <div class="container">
    <div class="profile-picture-container">
      <img :src="imageSrc" class="profile-picture" alt="Profile Picture" />
      <input type="file" @change="onFileChange" class="file-input" id="file-input" />
      <label for="file-input" class="upload-button">
        <img src="../assets/page-img/add_photo_alternate.png" class="upload-icon" alt="Upload Icon" />
      </label>
    </div>
    <form @submit.prevent="submitProfile">
      <div class="input-container">
        <img src="../assets/page-img/account_circle.png" class="icon" alt="User Icon" />
        <input type="text" v-model="name" placeholder="ニックネーム" required />
      </div>
            <div class="input-container">
        <img src="../assets/page-img/wc.png" class="icon" alt="Gender Icon" />
        <select v-model="gender" required>
          <option disabled value="">性別を選択</option>
          <option value="male">男性</option>
          <option value="female">女性</option>
          <option value="other">その他</option>
        </select>
      </div>
      <div class="input-container">
        <img src="../assets/page-img/cake.png" class="icon" alt="Age Icon" />
        <input type="number" v-model="age" placeholder="年齢" required />
      </div>
      <div class="input-container">
        <img src="../assets/page-img/edit.png" class="icon" alt="statusComment Icon" />
        <textarea v-model="statusComment" placeholder="自己紹介" required></textarea>
      </div>
      <button type="submit" class="submit-button" @click="saveProfile">登録・修正</button>
    </form>

    <!-- <button @click="saveProfile">プロフィール保存</button> -->
    <!-- <button @click="goToProfileKuwa">知り合い一覧へ移動</button> -->

    <div class="back-to-profile" @click="goToProfileKuwa">知り合い一覧へ移動</div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

export default {
  data() {
    return {
      imageSrc: require('../assets/page-img/no-img.png'), // デフォルトのプロフィール画像
      imageBase64: null, // Base64形式の画像データを格納する変数
      name: '',
      gender: '',
      age: '',
      statusComment: '',
      fileContents: [],
    };
  },
  computed: {
    parsedContents() {
        return this.fileContents.map(file => {
          try {
            return JSON.parse(file.content);
          } catch (e) {
            console.error('Failed to parse JSON', e);
            return {};
          }
        });
      }
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
          this.imageBase64 = e.target.result; // Base64形式の画像データを変数に格納

          console.log('Base64 Encoded Image:', this.imageBase64);
          console.log(typeof this.imageBase64);

          this.inputMessage = this.imageBase64;
        };
        reader.onerror = (e) => {
          console.error('Error reading file:', e.target.error);
        };
        reader.readAsDataURL(file);
      } else {
        alert('画像ファイルを選択してください');
      }
    },
    submitProfile() {
      // プロフィール情報を送信する処理を追加
      console.log({
        imageSrc: this.imageSrc,
        name: this.name,
        gender: this.gender,
        age: this.age,
        statusComment: this.statusComment,
      });
      alert('プロフィールが登録されました！');
    },
    convertToJson() {
      const user = firebase.auth().currentUser;

      const userInfo = {
        name: this.name,
        gender: this.gender,
        age: this.age,
        statusComment: this.statusComment,
        imageBase64: this.imageBase64,
        uid : user.uid
      };
      return JSON.stringify(userInfo);
    },
    saveProfile(){
      let uid = firebase.auth().currentUser.uid;      
      this.saveTextToLocal(this.convertToJson(), uid + '.txt');
    },
    saveTextToLocal(text, filename) {
      const blob = new Blob([text], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename || 'download.txt';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    goBack() {
      this.$router.push({ name: 'UserProfile' });
    },
    async goToProfileKuwa(){
        this.$router.push('/Profilekuwa');
    },
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #ffffff;
  padding: 20px;
  box-sizing: border-box;
}

.profile-picture-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}

.profile-picture {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.file-input {
  display: none;
}

.upload-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: #ff6666;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.upload-icon {
  width: 20px;
  height: 20px;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  border: 1px solid #000;
  border-radius: 20px;
  padding: 10px;
  box-sizing: border-box;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

input, textarea {
  flex: 1;
  border: none;
  outline: none;
}

textarea {
  resize: none;
  height: 60px;
}

.submit-button {
  width: 100%;
  max-width: 400px;
  height: 50px;
  background-color: #ff6666;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
}

.back-to-profile {
  margin-top: 30px;
  font-size: 18px;
  color: #666;
  cursor: pointer;
}
</style>
