<!-- <template>
    <div>
      <h1>QR Code Reader</h1>
      <div id="reader" style="width:500px; height:500px;"></div>
      <div>
        <h3 @click="selectAcquaintance(decodedText)"> QR読み取り後、ここを押したらチャットできるよ！</h3>

        <MyChat v-if="selectedAcquaintance" :selectedAcquaintance="selectedAcquaintance" />
      </div>


    </div>
    <button @click="goToUpdateQRCode">QRコード更新ページに移動</button>
  </template> -->

<template>
  <div class="container">
    <div id="reader" style="width:350px; height:350px;"></div>
    <div>
      <h3 @click="selectAcquaintance(decodedText)"> QR読み取り後、ここを押したらチャットできるよ！</h3>
      <MyChat v-if="selectedAcquaintance" :selectedAcquaintance="selectedAcquaintance" />
    </div>

    <button class="load-button" @click="startUpdatingQR">QRコードの更新を開始</button>
    <div class="back-to-profile-edit" @click="goToProfileKuwa">プロフィールページ</div>

    <div class="circle-buttons">
      <button class="circle-button" @click="goToUpdateQRCode"><img src="../assets/page-img/qr_code.png" alt="チャットを表示"></button>
    </div>
  </div>
</template>
  
  <script>
  import { Html5Qrcode } from 'html5-qrcode';
  import { ref } from 'vue';
  import MyChat from './MyChat.vue';

  export default {
    data() {
      return {
        html5QrCode: null,
        decodedText: ''
      };
    },
    components: {
    MyChat
  },
    setup() {
      const acquaintances = ref([]);
      const selectedAcquaintance = ref(null);

      const selectAcquaintance = (acquaintance) => {
        console.log(acquaintance);
        selectedAcquaintance.value = acquaintance
      }
      return { 
        selectAcquaintance,
        selectedAcquaintance,
        acquaintances

      };
    },
    mounted() {
      this.html5QrCode = new Html5Qrcode("reader");
      this.startQrCodeReader();
    },
    methods: {
      async goToUpdateQRCode(){
        this.$router.push('/UpdateQRCode');
      },
      async goToProfileKuwa(){
        this.$router.push('/Profilekuwa');
      },
      unshiftString(str, shift) {
        return str.split('').map(char => {
          const charCode = char.charCodeAt(0);
          return String.fromCharCode(charCode - shift);
        }).join('');
      },
      startQrCodeReader() {
        Html5Qrcode.getCameras().then((devices) => {
          if (devices && devices.length) {
            const cameraId = devices[0].id;
            this.html5QrCode.start(
              cameraId,
              {
                fps: 10,    // スキャンのフレームレート
                qrbox: 250  // スキャンエリアのサイズ
              },
              this.onScanSuccess,
              this.onScanFailure
            ).catch((err) => {
              console.error("Unable to start QR code scanning.", err);
            });
          }
        }).catch((err) => {
          console.error("Unable to get cameras.", err);
        });
      },
      isTimestampValid(inputString) {
        // 入力文字列を':'で分割
        const parts = inputString.split(':');
        // 三番目の文字列が存在するかチェック
        if (parts.length < 3) {
          console.log("Invalid input string format.");
            return false; // 形式が正しくない
        }


        //const timestampPart = parts[2];
        const timestamp = parseInt(parts[2]);

        // 現在のタイムスタンプを取得
        const currentTimestamp = Date.now();

        // タイムスタンプの差が10秒以内かどうかを確認
        console.log("aaaaa")
        console.log(currentTimestamp);
        console.log(timestamp);
        console.log(currentTimestamp - timestamp);
        return (currentTimestamp - timestamp) <= 10000;
      },
      onScanSuccess(decodedText) {
        decodedText = this.unshiftString(decodedText, 3);
        // QRコードが正常にスキャンされたときの処理
        if(this.isTimestampValid(decodedText)){
          const parts = decodedText.split(':');
          this.decodedText = parts[0];
        }

        
        //console.log(`Decoded text: ${decodedText}`);

        //console.log(`Timestamp is valid: ${this.isTimestampValid(decodedText)}`);
  
        // スキャンを停止
        this.html5QrCode.stop().then(() => {
          console.log("QR code scanning stopped.");
        }).catch((err) => {
          console.error("Unable to stop scanning.", err);
        });
      }
    },
    beforeUnmount() {
      if (this.html5QrCode) {
        this.html5QrCode.stop().catch((err) => {
          console.error("Unable to stop scanning.", err);
        });
      }
    }
  };
  </script>
  
<style scoped>
#reader {
  width: 100%;
  height: auto;
}

.container {
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.qr {
  width: 200px; /* Adjust the width as needed */
  height: 200px; /* Adjust the height as needed */
}

p {
  margin: 5px 0;
  font-size: 0.9rem;
}

.load-button {
  width: 100%;
  max-width: 300px;
  height: 50px;
  background-color: #ff6666;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 0;
  box-sizing: border-box;

  /* ボトムに固定するための追加スタイル */
  position: fixed;
  bottom: 110px; /* ボトムからの距離を調整 */
  left: 50%;
  transform: translateX(-50%); /* 中央に配置 */
  z-index: 1000; /* 他の要素の上に表示されるように調整 */
}

.circle-buttons {
  display: flex;
  justify-content: center;
  gap: 20px; /* ボタン間のスペース */
  position: fixed;
  bottom: 50px; /* 画面の底からの距離 */
  right: 5%;
  transform: translateX(-50%);
  z-index: 1000;
}

.circle-button {
  width: 50px;
  height: 50px;
  background-color: #ff6666;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-button img {
  width: 70%;
  height: 70%;
}

.back-to-profile-edit {
  width: 100%;
  height: 50px;
  margin: 10px 0;

  font-weight: bold;
  max-width: 300px;

  margin-top: 30px;
  font-size: 18px;
  color: #666;
  cursor: pointer;

  /* ボトムに固定するための追加スタイル */
  position: fixed;
  bottom: 30px; /* ボトムからの距離を調整 */
  left: 50%;
  transform: translateX(-50%); /* 中央に配置 */
  z-index: 1000; /* 他の要素の上に表示されるように調整 */
}

@media (max-width: 768px) {

}
</style>
