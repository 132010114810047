<template>
  <div class="container">
    <img class="logo" src="../assets/page-img/illust.png" alt="HackTail Logo" />
    <form @submit.prevent="signup">
      <div class="input-container">
        <img src="../assets/page-img/mail.png" class="icon" alt="Mail Icon" />
        <input type="email" v-model="email" placeholder="メールアドレス" required />
      </div>
      <div class="input-container">
        <img src="../assets/page-img/key_vertical.png" class="icon" alt="Key Icon" />
        <input type="password" v-model="password" placeholder="パスワード" required />
      </div>
      <button type="submit" class="create-button">新規作成</button>
    </form>
    <div class="back-to-home" @click="goBack">ホームへ戻る</div>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export default {
  data() {
    return {
      email: '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    async signup() {
      try {
        await firebase.auth().createUserWithEmailAndPassword(this.email, this.password);
        alert("Account created successfully!");
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    goBack() {
      this.$router.push({ name: 'Home' });
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #ffffff;
  padding: 20px;
  box-sizing: border-box;
}

.logo {
  width: 150px;
  height: 150px;
  margin-bottom: 50px;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  border: 1px solid #000;
  border-radius: 20px;
  padding: 10px;
  box-sizing: border-box;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

input {
  flex: 1;
  border: none;
  outline: none;
}

.create-button {
  width: 100%;
  max-width: 400px;
  height: 50px;
  background-color: #ff6666;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
}

.back-to-home {
  margin-top: 30px;
  font-size: 18px;
  color: #666;
  cursor: pointer;
}

.error-message {
  margin-top: 20px;
  color: red;
}
</style>
