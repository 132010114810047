<!-- src/components/Chat.vue -->
<template>
  <div class="chat">
    <h2>チャット</h2>
    <div v-if="user && selectedAcquaintance">
      <div class="messages">
        <div v-for="message in messages" :key="message.id" :class="{'my-message': message.senderUid === user.uid, 'their-message': message.senderUid !== user.uid}">
          <p>{{ message.text }}</p>
        </div>
      </div>
    </div>


    <div id="image-container"></div>

    <div>

    あいてからのメッセージは{{ p2pmsg }}

    <div>
      <span style="font-weight: bold">ID: </span>
      <input type="text" v-model="inputMessage" title="Input the message to send">
      <button @click="connect">Connect</button>
    </div>

    <button @click="downloadImg">受信した画像をダウンロードを保存</button>

    <h2>Receiver</h2>
    <div id="status">{{ statusMessage }}</div>
    </div>

    <body>
      <div id="wrapper">
        <video id="local-video" width="400" height="300" autoplay muted></video>
        <video id="remote-video" width="400" height="300" autoplay></video>
        <div class="clear"></div>
      </div>
    </body>

    <body>
      <button @click="makeCall">電話ボタン</button>
    </body>

    <div>
      転送したいファイルを下記に入力してね！
      <input type="file" @change="loadFile">
    </div>

    <button @click="saveHisProfile">転送されたデータを保存</button>

  </div>
</template>
<script>
import { ref, onMounted, watch } from 'vue';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import {Peer} from 'peerjs';

export default {
  data() {
    return {
      inputMessage: '何か入れてね',
      receiverId: '',
      statusMessage: 'Waiting for connection...',
      lastPeerId: null,
      peer: null,
      conn: null,
      msgContent: 'kuwaxkuwax',
      p2pmsg: "",
      friendId: '',
      localVideo: "",
      remoteVideo: "",
      // Video and audio are enabled for this call.
      localStream: "",
      MediaConfigurtion: {
        audio: true,
        video: true,
      },
      imageSrc: null, // 画像データを表示するための変数
      imageBase64: null, // Base64形式の画像データを格納する変数
      name: '',
      gender: '',
      age: null,
      statusComment: '',
      fileContents: []
    };
  },
  props: {
    selectedAcquaintance: {
      type: Object,
      required: true
    }
  },
  methods: {
    saveHisProfile(){
      this.saveJsonAsTxt(this.p2pmsg);
    },
    saveJsonAsTxt(jsonString) {
      try {
        // JSON文字列をパースしてオブジェクトに変換
        const obj = JSON.parse(jsonString);
        // uidプロパティの値をファイル名として使用
        const fileName = `${obj.uid}.txt`;
        // Blobを使用してテキストファイルの内容を設定
        const blob = new Blob([jsonString], { type: 'text/plain' });
        // BlobオブジェクトにアクセスするためのURLを生成
        const url = URL.createObjectURL(blob);
        // aタグを動的に生成してダウンロードをトリガー
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // 生成したURLを解放
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error saving JSON as txt:', error);
      }
    },
    loadFile(event) {
      const file = event.target.files[0]; // 選択されたファイルを取得
      if (file && file.type.match('text/plain')) { // ファイルがテキストファイルであることを確認
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContent = e.target.result;
          console.log(fileContent); // ファイルの内容をコンソールに表示
          this.inputMessage = fileContent; // ファイルの内容をテキストボックスに表示
        };
        reader.readAsText(file); // ファイルの内容をテキストとして読み込む
      } else {
        console.error('The selected file is not a text file.');
      }
    },
    convertToJson() {
      const user = firebase.auth().currentUser;

      const userInfo = {
        name: this.name,
        gender: this.gender,
        age: this.age,
        statusComment: this.statusComment,
        imageBase64: this.imageBase64,
        uid : user.uid
      };
      return JSON.stringify(userInfo);
    },
    handleFiles(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.fileContents.push({
            name: file.name,
            content: e.target.result
          });
        };
        reader.readAsText(file); // テキストファイルとして読み込む
      }
    },

    saveTextToLocal(text, filename) {
      const blob = new Blob([text], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename || 'download.txt';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },

    downloadImg() {
      this.saveBase64AsFile(this.p2pmsg, 'image.jpg');
    },

    saveBase64AsFile(base64, fileName) {
      // デコードされたバイナリデータを取得
      const byteString = atob(base64.split(',')[1]);
      const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];

      // バイナリデータのバッファを生成
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      // バッファにバイナリデータをコピー
      for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i);
      }

      // Blobオブジェクトを生成
      const blob = new Blob([arrayBuffer], { type: mimeString });

      // ダウンロードリンクを生成
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
  },

    readImage(event) {
      const file = event.target.files[0];
      if (!file) {
        console.log('No file selected');
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        this.imageSrc = e.target.result; // 画像データを表示用に変数に格納
        this.imageBase64 = e.target.result; // Base64形式の画像データを変数に格納

        console.log('Base64 Encoded Image:', this.imageBase64);
        console.log(typeof this.imageBase64);


        this.inputMessage = this.imageBase64;

      };

      reader.onerror = (e) => {
        console.error('Error reading file:', e.target.error);
      };

      reader.readAsDataURL(file); 
    },
    initialize() {
      this.localVideo= document.getElementById("local-video");
      this.remoteVideo= document.getElementById("remote-video");



      this.peer = new Peer(null, { debug: 2 });
      this.peer.on('open', (id) => {
        if (this.peer.id === null) {
          console.log('Received null id from peer open');
          this.peer.id = this.lastPeerId;
        } else {
          this.lastPeerId = this.peer.id;
        }
        console.log('ID: ' + this.peer.id);
        console.log('aaa: ' + id);



        //firebaseにIDを登録
        const user = firebase.auth().currentUser;
        const userRef = firebase.firestore().collection('users').doc(user.uid);
        userRef.update({
          peerId: this.peer.id
        });



      });
      this.peer.on('connection', (c) => {
        if (this.conn && this.conn.open) {
          c.on('open', () => {
            c.send('Already connected to another client');
            setTimeout(() => c.close(), 500);
          });
          return;
        }
        this.conn = c;
        console.log('Connected to: ' + this.conn.peer);
        this.statusMessage = 'Connected';

        this.receiverId=this.conn.peer;

        this.ready();
      });
      this.peer.on('disconnected', () => {
        this.statusMessage = 'Connection lost. Please reconnect';
        console.log('Connection lost. Please reconnect');
        this.peer.id = this.lastPeerId;
        this.peer._lastServerId = this.lastPeerId;
        this.peer.reconnect();
      });
      this.peer.on('close', () => {
        this.conn = null;
        this.statusMessage = 'Connection destroyed. Please refresh';
        console.log('Connection destroyed');
      });
      this.peer.on('error', (err) => {
        console.log(err);
        alert('' + err);
      });


      this.listenCall();
    },
  


    listenCall() {
        var getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia;
        this.peer.on("call", (call) => {
          getUserMedia(this.MediaConfigurtion, (stream) => {
            this.localVideo.srcObject = stream;
            this.locaStream = stream;
            call.answer(stream);
            call.on("stream", (remoteStream) => {
              this.remoteVideo.srcObject = remoteStream;
            });
          });
        });
      },

      makeCall() {
      var getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;
      getUserMedia(this.MediaConfigurtion, (stream) => {
        this.localVideo.srcObject = stream;
        this.localStream = stream;
        const call = this.peer.call(this.receiverId, stream);
        call.on("stream", (remoteStream) => {
          this.remoteVideo.srcObject = remoteStream;
        });
      });
    },



    ready() {
      this.conn.on('data', (data) => {
        console.log('Data received:');
        console.log(data);
        this.p2pmsg=data;
      });
      this.conn.on('close', () => {
        this.conn = null;
      });
    },

    SaveProfile(){
      let uid = firebase.auth().currentUser.uid;      

      this.saveTextToLocal(this.convertToJson(), uid + '.txt');
    },


    


    join() {
      if (this.conn) {
        this.conn.close();
      }

      console.log('Acquaintance is : '+this.selectedAcquaintance);

      //this.selectedAcquaintance.uidのpeerIdを取得
      const userRef = firebase.firestore().collection('users').doc(this.selectedAcquaintance);
      userRef.get().then((doc) => {
        if (doc.exists) {
          console.log('Ddata:', doc.data().peerId);
          this.receiverId = doc.data().peerId;
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!');
        }
      }).catch((error) => {
        console.log('Error getting document:', error);
      });



      this.conn = this.peer.connect(this.receiverId, { reliable: true });
      this.conn.on('open', () => {
        console.log('Connected to: ' + this.conn.peer);
        this.statusMessage = 'Connected';
        this.conn.send(this.inputMessage); // テキストボックスの内容を送信する
      });
    },
    connect() {
      this.join();
    },
    async loadAndSendImage() {
      let imagePath = './xxxx.jpeg';
      try {
        const response = await fetch(imagePath);
        const imageBlob = await response.blob();
        const reader = new FileReader();
        
        reader.onloadend = () => {
          const base64data = reader.result;

          console.log('base64data:', base64data);

          this.inputMessage = base64data;
        };
        
        reader.readAsDataURL(imageBlob);
      } catch (error) {
        console.error('Error loading image:', error);
      }
    },
    async saikochiku() {
      this.displayImage(this.p2pmsg)
    },

    displayImage(base64data) {
      // 新しいImageオブジェクトを作成
      const image = new Image();
      
      // エンコードされた文字列をImageオブジェクトのsrc属性に設定
      image.src = base64data;
      
      // 画像をHTMLに追加するための要素を取得（例えば、idが'image-container'のdiv）
      const container = document.getElementById('image-container');
      
      // 画像をコンテナに追加
      container.appendChild(image);
    },

  },
  setup(props) {
    const user = ref(null);
    const messages = ref([]);
    const newMessage = ref('');
    const chatId = ref('');

    const fetchMessages = async () => {
      if (!user.value || !props.selectedAcquaintance) {
        return;
      }
      chatId.value = [user.value.uid, props.selectedAcquaintance.uid].sort().join('_');
      const messagesRef = firebase.firestore().collection('chats').doc(chatId.value).collection('messages').orderBy('timestamp');
      messagesRef.onSnapshot(snapshot => {
        messages.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      });
    };

    const sendMessage = async () => {
      if (!user.value || !newMessage.value) {
        return;
      }
      const chatDocRef = firebase.firestore().collection('chats').doc(chatId.value);
      const messagesRef = chatDocRef.collection('messages');
      await messagesRef.add({
        senderUid: user.value.uid,
        text: newMessage.value,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
      });
      newMessage.value = '';
    };

    onMounted(() => {
      firebase.auth().onAuthStateChanged(currentUser => {
        user.value = currentUser;
        fetchMessages();
      });
    });

    watch(() => props.selectedAcquaintance, fetchMessages);

    return {
      user,
      messages,
      newMessage,
      sendMessage
    };
  },
  mounted(){
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/peerjs@1.3.1/dist/peerjs.min.js';
    //script.onload = () => {
      //this.connectButton.disabled = false;
    //};
    document.head.appendChild(script);
    this.initialize();
  }
};
</script>
<style scoped>
.chat {
  max-width: 600px;
  margin: auto;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.messages {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 4px;
}

.my-message {
  text-align: right;
}

.their-message {
  text-align: left;
}

form {
  display: flex;
}

input {
  flex: 1;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 0.5em 1em;
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
